<template>
	<div class="all about">
		<div class="logo">
			<img class="logoImg" src="../../src/static/logo.png" alt="" srcset="" />
			<div class="lianjie">
			  <span>連接</span>
			  <div class="quan">
				<img src="../../src/static/youshang.png" alt="" srcset="">
			  </div>
			</div>
		  </div>
		<div class="daikaifa">
			<div class="title">待開發... ... 盡請期待</div>
			<div class="boxssss">

			</div>
		</div>
		<tab :pageActive="2"></tab>
	</div>
</template>

<!-- lang="ts" -->
<script>
	import Web3 from 'web3';
	import MyContractABI from "../abi/main.json"; // 导入合约 ABI
	import { message } from 'ant-design-vue'
import tab from "../../src/components/tab.vue";
	
	export default {
		// computed: {
		//   author () {
		//     return process.env.VUE_APP_AUTHOR;
		//   },
		//   apiBaseUrl () {
		//     return process.env.VUE_APP_API_BASE_URL;
		//   }
		// }
		components: {
			tab
		},
		data() {
			return {
				defaultProps: {
					children: 'children',
					label: 'label'
				},
				contractAddress:"0x6abe8194c76545bf0d24191ec4b034810e2d0fa6",
				userAddress: "",
				dataStat: [0,0,0,0,0,0,0,0,0,0,0],
				tokenName:'PMM',
				mintAmount:0,
				price:0,
				dataStat2: [0,0,0,0,0,0,0,0],
				dataStat3: ["0x0000000000000000000000000000000000000000","0x0000000000000000000000000000000000000000"
				,"0x0000000000000000000000000000000000000000",0,0],
				getrefMap:["0x0000000000000000000000000000000000000000",0,0,0,0,0,0],
				getrefMap2:[0,0],
				
				param: [0,0,0,"0x0000000000000000000000000000000000000000","0x0000000000000000000000000000000000000000",0,0,0,0,0],
				exchangeAmount: 0,
				data2: {},
				bnbBalance: 0,
				web3: {},
			}
		},
		created() {
			
		},
		methods: {
			back(){
				this.$router.go(-1);
			},
		}
	}
</script>


<style>
	body{
		background-image: url('../static/bg2.jpg');
		background-size: 100% 100%;
	}
	.all{
	}
	.content {
	    display: flex;
	    flex-direction: column;
	    align-items: center;
	    justify-content: center;
	    }
	    .second{
	    width: 90%;margin:20px auto;border-radius: 15px;display: block;border: 3px solid #4f82b4;background: rgba(14, 12, 32, 0.50);box-shadow: 4px -2px 12px 0px rgba(157, 173, 255, 0.16);
	    }
	    .second01{
	    width: 90%;margin: auto;display: flex;flex-direction: row;align-items: center;justify-content: space-between;padding: 30px 0;
	    }
	    .second02{
	    font-size: 32px;color: #7d7d7d;
	    }
	    .second03{
	    width: 40px;height: 40px;
	    }
	    .second04{
	    width: 90%;margin: 18px auto;border: 1px solid #d9d9d9;height: 180px;border-radius: 20px;display: block;
	    }
	    .second05{
	    width: 90%;margin: 25px auto;display: flex;flex-direction: row;align-items: center;justify-content: space-between;
	    }
	    .second09{
	      display: inline-block;width: 62px;height: 40px;border-radius: 12px;text-align: center;line-height: 40px;background-color: #7C3BED33;color: #7C3BED;font-size: 24px;margin-left: 20px;
	    }

		.imglistbox{
			display: flex;
			align-content: center;
			justify-content: space-between;
			flex-wrap: wrap;
		}

		.imglistbox img{
			width: 23%;
			height: 80px;
			margin-top: 10px;
			margin-bottom: 6px;
		}

		.daikaifa .title{
			text-align: center;
			font-size: 20px;
			color: #F5B22F;
		}

		
.about {
	box-sizing: border-box;
	position: fixed;
	height: 100vh;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 10;
	overflow-y: scroll;
  }
  
  .about .logo {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding: 20px;
  }
  
  .logoImg {
	width: 112px;
	height: auto;
  }

  .about .boxssss{
	height: 388px;
	width: 90%;
	border-image: linear-gradient(180deg, #000000 0%, rgba(255, 255, 255, 0.63) 52%, #39393A 100%) 2;
	border-radius: 15px;
	
background: #212024;
margin: 0 auto;
margin-top: 20px;
  }
  
</style>